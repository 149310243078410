@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  background-color: #ffffff;
  margin: 0px;
}

p {
  font-family: 'DM Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #4A4A4A;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'DM Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #51769A;
  font-weight: 600;
}

/* dm-sans-latin-400-normal */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/dm-sans@latest/latin-400-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/dm-sans@latest/latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* dm-sans-latin-700-normal */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 700;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/dm-sans@latest/latin-700-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/dm-sans@latest/latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.App-logo {
  height: 4vmin;
  pointer-events: none;
}

.inlineImage {
  height: 5vmin;
}

.brandAccent {
  width: 100vw;
  height: auto;
}

@media (min-width:720px) {
  .flexHero {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
  }

  .flexHero h1 {
    margin: 0px;
  }

  .lineHero {
    display: flex;
    flex-direction: row;
    gap: 14px;
    justify-content: center;
    align-items: center;
  }

  .mainContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    height: 100vh;
    gap: 3vh;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .flexFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    padding-top: 32px;
  }

  .footerHeadline {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }

  .footerLeft {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-left: 40px;
    background-color: #5A636C;
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 40px;
  }

  .flexSubtitle {
    display: flex;
    flex-direction: 'row';
    align-items: center;
    gap: 4px;
    margin-top: 2px;
  }

  .playStoreIcon {
    height: 16px;
    width: 16px;
  }

  .flexSubtitle p {
    font-size: 14px;
    color: rgba(255, 255, 255, .8);
    font-weight: 400;
  }

  .footerLeft p {
    margin: 0
  }

  .iconSquare {
    margin-left: 40px;
    margin-top: 40px;
  }

  .footerRight {
    padding-right: 40px;
    opacity: .6;
  }

  .footerRight p {
    font-size: 14px;
    color: rgba(0, 0, 0, .8);
    font-weight: 400;
  }

  .flexHero-mobile {
    display: none;
  }


}

@media (max-width:719px) {

  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .flexHero {
    display: none;
  }

  .flexFooter {
    display: none;
  }

  .iconSquare {
    display: block;
    margin: 0 auto;
    padding-top: 20vh;
  }

  .flexHero-mobile {
    padding: 40px;
    /* margin-top: 10vh; */
  }

  .flexHero-mobile p {
    margin: 0;
    text-align: center;
    color: white;
    vertical-align: baseline;
  }

  .comingSoon {
    padding: 16px;
    background-color: #5A636C;
    border-radius: 12px;
    width: 190px;
    margin: 0 auto;
    margin-top: 64px;
  }

}