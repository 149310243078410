.legalContainer{
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 32px;
}

.legalContainer p{
    color: #4a4a4a;
    line-height: 1.6;
    margin: 0px;
}

.legalContainer h1, h2, h3, h4, h5, ol, li, p{
    font-family:'DM Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: #333;
    margin: 0px
}

li{
    margin-bottom: 12px;
}

li > p{
    padding-left: 12px;
    font-family:'DM Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

ol{
    padding-left: 12px;
}

.textAlignCenter{
    text-align: center;
}